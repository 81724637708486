@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ShopOverviewInfo";

#{$selector} {
  &__headline {
    @include text("base");
    padding-bottom: $space-lg;
    font-weight: bold;
  }

  &__content {
    @include text("meta");
    margin-bottom: 0 !important;

    &__date {
      font-weight: 700;
    }
  }
}
