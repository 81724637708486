@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ShopOrderDetailsSummary";

#{$selector} {
  display: flex;
  flex-direction: column;

  &__summary {
    display: flex;
    flex-direction: column;
    gap: 15px;

    &__line {
      display: flex;
      flex-direction: row;
      gap: 15px;
      justify-content: space-between;
      margin: 15px;

      @include screenSmallerThan($screen-md) {
        flex-direction: column;
      }

      &__block {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 100%;
        font-size: medium;

        & > *:nth-child(1) {
          font-weight: bold;
          font-size: small;
        }
      }
    }
  }
}
