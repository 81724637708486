@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".BookingOverview";

#{$selector} {
  &__table {
    margin-top: $space-3xl;
  }

  &__headline {
    margin-top: $space-xl;
  }

  &__description {
    padding-top: $space-md;
    color: $text-primary;
  }

  &__external__bookings {
    display: inline-block;
    width: 25%;
    margin-top: $space-lg;
    text-decoration: none !important;

    &__logo {
      max-height: 30px;
    }

    &__card {
      @include when-hovered {
        background-color: rgba($background-gray, 0.15);
        transition: background-color 0.3s linear;
      }
    }
  }
}
