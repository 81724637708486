@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ShopOverviewInfo";

#{$selector} {
  &__headline {
    @include text("component/documentBox/headline");
    padding-bottom: $space-lg;
  }

  &__content {
    @include text("meta");
    margin-bottom: 0 !important;
  }
}
