@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ShopOrderDetails";

#{$selector} {
  &__description {
    @include text("meta");
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: calc($space-lg + 24px);

    & > h1 {
      margin: 0 !important;
    }

    & > div:first-child {
      display: flex;
    }

    &__status {
      @include text("service/detail/status");
      height: 30px;
      margin-top: $space-xs;
      margin-right: $space-lg;
      margin-left: $space-lg;
      padding: $space-xs $space-md;
      background-color: $background-gray;
      border-radius: $ui-default-border-radius;

      &--Ordered {
        background-color: $background-success;
      }

      &--Released {
        background-color: $background-success;
      }

      &--Closed {
        background-color: $background-error;
      }
    }
  }

  &__summary,
  &__address,
  &__details {
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-bottom: 25px;
  }

  &__print {
    display: none;
  }
}
