@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".AccountOverviewOrganization";

#{$selector} {
  &__content {
    margin: $space-2xl 0 !important;
  }

  &__form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &__headline {
      width: 100%;
      margin-top: $space-lg;
      margin-bottom: $space-lg;
    }

    &__field {
      width: calc(50% - $space-lg);
      margin-bottom: $space-lg;

      &--has-fullWidth {
        width: 100%;
      }
    }
  }

  &__button {
    margin-top: $space-2xl;
  }
}
