@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ShopCartOverviewBillingOptionsCard";

#{$selector} {
  display: flex;
  flex-direction: column;
  min-height: 100px;

  li {
    padding: 0 50px 10px 0;
    line-height: 1.5;
  }

  &__billinglist {
    width: 100%;
  }

  &__label {
    &--is-disabled {
      color: $text-lightgray;
    }
  }
}
