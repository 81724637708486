@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ShopOverviewProductsHeader";

#{$selector} {
  &__toolbar {
    display: grid;
    grid-gap: 0 $space-md;
    grid-template-columns: 2fr 1fr;
  }

  &__input {
    height: 56px;
    background-color: $background-base;
  }

  &__content__wrapper {
    padding-bottom: $space-lg;
  }

  &__accordion__label {
    font-size: 1.3em !important;
  }

  &__info {
    @include text("meta");
    padding-bottom: $space-lg;

    &__title {
      display: block;
      font-weight: 700;
    }
  }

  &__content {
    @include text("meta");
  }
}
