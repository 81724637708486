@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".AccountOverviewPrivate";

#{$selector} {
  &__content {
    margin: $space-2xl 0 !important;
  }

  &__items {
    margin-left: 0 !important;
  }

  &__item {
    display: flex;
    flex-direction: column;
    margin-top: $space-lg;

    &__title {
      @include text("service/account/overview/title");
      padding-bottom: $space-sm;
    }
  }

  &__action {
    margin-top: $space-2xl;

    &__button {
      margin-top: $space-lg;
    }
  }
}
