@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ShopDetailHeaderNavigation";

#{$selector} {
  padding-bottom: $space-lg;

  &__link {
    display: flex;
    align-items: center;

    &__label {
      @include text("base");
      padding-left: $space-sm;
    }
  }
}
