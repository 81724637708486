@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ShopOverviewNavigation";

#{$selector} {
  &__title {
    @include text("service/shop/overview/navigation/title");
    display: block;
    padding-bottom: $space-md;
  }
}
