@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".AccountOverviewWebshop";

#{$selector} {
  &__state {
    margin-top: $space-lg !important;

    &__text {
      display: inline-flex;
      align-items: center;
      font-size: 0.8em;

      &__icon {
        &__waiting {
          margin-right: $space-sm;
          color: $icon-base;
        }

        &__completed {
          margin-right: $space-sm;
          color: $icon-primary;
        }

        &__cancelled {
          margin-right: $space-sm;
          color: $text-error;
        }
      }
    }
  }

  &__info {
    @include text("meta");

    &__text {
      font-size: medium;
    }
  }

  &__button {
    margin-top: $space-lg !important;
  }
}
