@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ShopOrders";

#{$selector} {
  h6 {
    margin-top: $space-xl;
  }

  &__table {
    margin-top: $space-lg;
  }

  &__nodata {
    margin: $space-xl;
    font-weight: bold;
    font-size: small;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: $space-lg;
  }
}
