@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ShopDetailOptionalProducts";

#{$selector} {
  &__container {
    display: flex;
    flex-direction: column;
    gap: $space-xs;
    align-items: flex-start;
    justify-content: center;
    margin: 0 $space-xs;
  }

  &__info {
    @include text("meta");

    &__text {
      font-size: 1em;
    }
  }

  &__products {
    display: grid;
    grid-gap: $space-md;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: $space-md;

    @include screenSmallerThan($screen-xl) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include screenSmallerThan($screen-lg) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__product {
    position: relative;
    flex: auto;
    gap: 1px;
    align-items: center;
    justify-content: center;
    min-width: 14vw;
    margin-left: $space-xs;
    padding: $space-lg $space-md;
    background-color: $background-base;
    border: 2px solid $border-lightgray;
    border-radius: 5px;
    box-shadow: $ui-elevation-button;
    cursor: pointer;

    &__tag,
    &__favorite {
      @include text("meta");
      position: absolute;
      top: -5px;
      right: -5px;
      z-index: 1;
      width: 35px !important;
      height: 35px !important;
      padding: $space-xs $space-sm;
      color: $text-primary;
      border-radius: 1rem;
    }

    &__title {
      @include text("headline/xs");
      display: block;
      padding-bottom: $space-sm;
      text-align: center;
    }

    &__price {
      @include text("headline/xs");
      display: block;
      color: $text-base;
      font-weight: 700;
      text-align: center;

      &__unit {
        color: $text-base;
      }

      &--has-discount {
        color: $text-error;
      }
    }

    @include when-hovered {
      background-color: transparent;
      border-color: $border-primary;
      transition: border-color 0.3s linear;
    }
  }
}
