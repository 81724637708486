@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ShopDetailHeader";

#{$selector} {
  &__header {
    display: grid;
    grid-gap: 0 $space-md;
    grid-template-columns: 1fr 1fr;

    @include screenSmallerThan($screen-md) {
      grid-template-columns: 1fr;
    }
  }
}
