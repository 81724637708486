@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ShopOverview";

#{$selector} {
  background-color: rgba($background-primary, 0.05);

  &__wrapper {
    display: flex;

    @include screenSmallerThan($screen-lg) {
      flex-direction: column-reverse;
    }
  }

  &__headline__wrapper {
    display: flex;
    align-items: center;
  }

  &__headline__icon {
    margin: $space-xs $space-xs $space-xl;
    color: $background-primary;
    background-color: transparent;
  }

  &__sidebar {
    width: 33.333%;
    padding-left: $space-md;

    @include screenSmallerThan($screen-lg) {
      width: 100%;
      padding-left: 0;
    }
  }

  &__products {
    width: 77.777%;
    padding-right: $space-md;

    @include screenSmallerThan($screen-lg) {
      width: 100%;
      padding-right: 0;
    }
  }
}
