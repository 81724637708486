@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ShopCartOverviewTermsOfServicesCard";

#{$selector} {
  display: flex;
  flex-direction: column;
  min-height: 100px;

  li {
    padding: 0 50px 10px 0;
    line-height: 1.5;
  }

  &__contents {
    padding-bottom: 0 !important;
  }

  &__toslist {
    width: 100%;
  }

  &__toslink {
    color: $text-primary;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    padding-top: 0 !important;
  }

  &__category {
    margin-right: $space-sm;
    font-size: small;
  }
}
