@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ShopDetail";

#{$selector} {
  & > div {
    margin-bottom: $space-2xl;
  }
}
