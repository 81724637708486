@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ContentPage";

#{$selector} {
  &__card__content {
    display: block;

    & p {
      margin-bottom: 0 !important;
    }
  }
}
