@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ShopStandBookingRequestsOverview";

#{$selector} {
  &__table {
    margin-top: $space-3xl;
  }

  &__description {
    padding-top: $space-md;
    color: $text-primary;
  }
}
