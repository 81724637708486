@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ShopIncidents";

#{$selector} {
  &__table {
    margin-top: $space-lg;
  }

  &__nodata {
    margin: $space-xl;
    font-weight: bold;
    font-size: small;
  }
}
