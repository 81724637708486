@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ShopOverviewContact";

#{$selector} {
  &__headline {
    @include text("component/documentBox/headline");
    padding-bottom: $space-lg;
  }

  &__content {
    display: flex;
    flex-direction: row;
  }

  &__info {
    @include text("meta");
    margin-bottom: 0 !important;
    padding-left: $space-md;

    &__name {
      font-weight: 700;
    }
  }
}
