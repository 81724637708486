@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ShopOverviewProducts";

#{$selector} {
  li::marker {
    content: "";
  }

  &__pagination {
    padding: 5px 0;
  }

  &__products {
    display: grid;
    grid-gap: 0 $space-md;
    grid-template-columns: 1fr 1fr 1fr;

    &__product {
      display: flex;
    }

  }

  @include screenSmallerThan($screen-xl) {
    grid-template-columns: 1fr 1fr;
  }

  @include screenSmallerThan(650px) {
    grid-template-columns: 1fr;
  }
}
