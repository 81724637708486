@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ShopDetailContent";

#{$selector} {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: $space-xl 0 $space-lg 0;
  }
}
