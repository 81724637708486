@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Breadcrumb";

#{$selector} {
  margin-bottom: $space-md;

  &__link {
    &:first-of-type &__title {
      padding-left: 0;
    }
  }

  &__seperator {
    padding: 0 $space-sm;
  }

  &__title {
    @include text("breadcrumb");

    &--is-active {
      color: $text-base;
    }
  }
}
