@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ShopDetailHeaderContent";

#{$selector} {
  display: flex;
  flex-direction: column;
  gap: $space-lg;
  align-items: flex-start;
  justify-content: center;

  &__content {
    display: flex;
    flex-direction: column;
    gap: $space-xs;
    align-items: flex-start;
    justify-content: center;
    margin: $space-lg;

    &__title {
      @include text("headline/xl");
    }

    &__desc {
      @include text("base");
    }
  }

  &__product {
    &__tag {
      @include text("tag");
      z-index: 1;
      display: inline-block;
      margin: $space-md 0;
      padding: $space-xs $space-lg;
      color: $text-primary;
      background-color: $background-secondary;
      border-radius: 1rem;
    }

    &__note {
      @include text("meta");
    }
  }

  &__price {
    display: flex;
    flex-direction: column;
    gap: $space-xs;
    align-items: flex-start;
    justify-content: center;
    margin: $space-md;

    &__info {
      @include text("meta");
      display: flex;
    }

    &__mandatory {
      @include text("meta");
      display: flex;
      flex-direction: column;
    }

    &__wrapper {
      display: flex;
      margin: 0 $space-lg $space-lg $space-lg;
    }

    &__label {
      @include text("tag");
    }

    &__discount {
      @include text("headline/md");
      color: $text-error;
    }

    &__value {
      @include text("headline/md");
      color: $text-primary;

      &--has-discount {
        @include text("component/productCard/price");
        padding-left: $space-md;
        color: $text-primary;
        text-decoration: line-through;
        opacity: 0.6;
      }
    }

    &__desc {
      @include text("meta");
    }

    &__content {
      @include text("meta");
      padding-top: $space-lg;
    }
  }

  &__variants {
    display: grid;
    grid-gap: 0 $space-md;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: $space-xl;

    @include screenSmallerThan($screen-xl) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include screenSmallerThan($screen-lg) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__variant {
    position: relative;
    flex: auto;
    gap: 1px;
    align-items: center;
    justify-content: center;
    min-width: 7vw;
    margin-left: $space-lg;
    padding: $space-lg $space-md;
    padding-top: 25px;
    background-color: $background-base;
    border: 2px solid $border-lightgray;
    border-radius: 5px;
    box-shadow: $ui-elevation-button;
    cursor: pointer;

    &__tag, &__favorite {
      @include text("meta");
      position: absolute;
      top: -5px;
      right: -5px;
      z-index: 1;
      width: 35px !important;
      height: 35px !important;
      padding: $space-xs $space-sm;
      color: $text-primary;
      border-radius: 1rem;
    }

    &__title {
      @include text("headline/xs");
      display: block;
      padding-bottom: $space-sm;
      text-align: center;
    }

    &__price {
      @include text("headline/xs");
      display: block;
      color: $text-base;
      font-weight: 700;
      text-align: center;

      &__unit {
        color: $text-base;
      }

      &--has-discount {
        color: $text-error;
      }
    }

    &--is-active {
      background-color: transparent;
      border-color: $border-primary;
      transition: border-color 0.3s linear;

      &__price {
        color: $text-primary;
      }
    }

    @include when-hovered {
      background-color: transparent;
      border-color: $border-primary;
      transition: border-color 0.3s linear;
    }
  }

  &__button {
    margin: $space-lg;

    &__helper {
      @include text("meta");
      margin-top: $space-md;
      border-color: $border-primary !important;
    }
  }
}
